h1 {
	font-family: 'Homemade Apple', cursive;
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

h2 {
	font-family: 'Cabin', sans-serif;
	font-size: 3rem;
	color: #392f5a;
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
p {
	font-family: 'Cabin', sans-serif;
	font-size: 2rem;
	color: #392f5a;
	margin: 10px;
	animation: fadeInAnimation ease 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.scroll-arrow {
	margin: 0;
	line-height: 14px;
	transform: rotate(-14deg);
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 3s;
	opacity: 0;
}
.scroll-line {
	margin: 0;
	line-height: 16px;
	transform: rotate(-22deg);
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 3s;
	opacity: 0;
}

.scroll {
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 3s;
	opacity: 0;
}

.hint {
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-delay: 15s;
	opacity: 0;
	color: navy;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
